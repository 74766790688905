/**
 * @generated SignedSource<<0b3e04d4a808e909bcaaf9d021e7ec5d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CloudProvider = "AWS" | "Azure" | "GCP" | "Kubernetes" | "TencentCloud" | "%future added value";
export type AddAccountGroupInput = {
  description?: string | null | undefined;
  dynamicFilter?: string | null | undefined;
  items?: ReadonlyArray<AccountGroupElement> | null | undefined;
  name: string;
  priority?: number | null | undefined;
  provider: CloudProvider;
  regions?: ReadonlyArray<string> | null | undefined;
  shortName?: string | null | undefined;
  variables?: string | null | undefined;
};
export type AccountGroupElement = {
  key: string;
  provider: CloudProvider;
  regions?: ReadonlyArray<string> | null | undefined;
};
export type AddAccountGroupMutation$variables = {
  connections: ReadonlyArray<string>;
  input: AddAccountGroupInput;
};
export type AddAccountGroupMutation$data = {
  readonly addAccountGroup: {
    readonly group: {
      readonly dynamicFilter: string | null | undefined;
      readonly id: string;
      readonly name: string;
      readonly provider: CloudProvider;
      readonly regions: ReadonlyArray<string> | null | undefined;
      readonly uuid: string;
    } | null | undefined;
  } | null | undefined;
};
export type AddAccountGroupMutation = {
  response: AddAccountGroupMutation$data;
  variables: AddAccountGroupMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "AccountGroup",
  "kind": "LinkedField",
  "name": "group",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "provider",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "regions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dynamicFilter",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AddAccountGroupMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "AccountGroupPayload",
        "kind": "LinkedField",
        "name": "addAccountGroup",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AddAccountGroupMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "AccountGroupPayload",
        "kind": "LinkedField",
        "name": "addAccountGroup",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "group",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "AccountGroupEdge"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7e39cedf0d7536c4bddccdd8b5ca31cd",
    "id": null,
    "metadata": {},
    "name": "AddAccountGroupMutation",
    "operationKind": "mutation",
    "text": "mutation AddAccountGroupMutation(\n  $input: AddAccountGroupInput!\n) {\n  addAccountGroup(input: $input) {\n    group {\n      id\n      uuid\n      name\n      provider\n      regions\n      dynamicFilter\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7f436f4e9b5068b1196f049a65cf5bfa";

export default node;
