/**
 * @generated SignedSource<<0867db51a6faf1bb7673584ee0633a68>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type UpdateEmailConfigurationInput = {
  fromEmail?: string | null | undefined;
  name: string;
  scope: string;
  sesRegion?: string | null | undefined;
  smtp?: SmtpConfigurationInput | null | undefined;
};
export type SmtpConfigurationInput = {
  password?: string | null | undefined;
  port?: string | null | undefined;
  server: string;
  ssl?: boolean | null | undefined;
  username?: string | null | undefined;
};
export type UpdateEmailProfileMutation$variables = {
  input: UpdateEmailConfigurationInput;
};
export type UpdateEmailProfileMutation$data = {
  readonly updateEmailProfile: {
    readonly configuration: {
      readonly profile: string;
      readonly record: {
        readonly fromEmail?: string;
        readonly sesRegion?: string | null | undefined;
        readonly smtp?: {
          readonly port: string | null | undefined;
          readonly server: string;
          readonly ssl: boolean | null | undefined;
          readonly username: string | null | undefined;
        } | null | undefined;
      };
      readonly scope: string;
    };
  } | null | undefined;
};
export type UpdateEmailProfileMutation = {
  response: UpdateEmailProfileMutation$data;
  variables: UpdateEmailProfileMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "scope",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profile",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fromEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SmtpConfiguration",
      "kind": "LinkedField",
      "name": "smtp",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "server",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "port",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ssl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sesRegion",
      "storageKey": null
    }
  ],
  "type": "EmailConfiguration",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateEmailProfileMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ConfigurationPayload",
        "kind": "LinkedField",
        "name": "updateEmailProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Configuration",
            "kind": "LinkedField",
            "name": "configuration",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "record",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateEmailProfileMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ConfigurationPayload",
        "kind": "LinkedField",
        "name": "updateEmailProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Configuration",
            "kind": "LinkedField",
            "name": "configuration",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "record",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f838b0c678380a3021562df5ef82b055",
    "id": null,
    "metadata": {},
    "name": "UpdateEmailProfileMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateEmailProfileMutation(\n  $input: UpdateEmailConfigurationInput!\n) {\n  updateEmailProfile(input: $input) {\n    configuration {\n      scope\n      profile\n      record {\n        __typename\n        ... on EmailConfiguration {\n          fromEmail\n          smtp {\n            server\n            port\n            ssl\n            username\n          }\n          sesRegion\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "79ea8b28e045830482c8deb9b06eea85";

export default node;
