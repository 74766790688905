/**
 * @generated SignedSource<<8373a2bdbf22e43d87850014be0429cc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BindingStatusCard_runs$data = {
  readonly runs: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly executionErrorCount: number;
        readonly expectedExecutionCount: number;
        readonly lastUpdateTime: string | null | undefined;
        readonly observedExecutionCount: number;
        readonly startTime: string | null | undefined;
      };
    }>;
  } | null | undefined;
  readonly " $fragmentType": "BindingStatusCard_runs";
};
export type BindingStatusCard_runs$key = {
  readonly " $data"?: BindingStatusCard_runs$data;
  readonly " $fragmentSpreads": FragmentRefs<"BindingStatusCard_runs">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BindingStatusCard_runs",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BindingRunConnection",
      "kind": "LinkedField",
      "name": "runs",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BindingRunEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "BindingRun",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "startTime",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastUpdateTime",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "observedExecutionCount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "expectedExecutionCount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "executionErrorCount",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Binding",
  "abstractKey": null
};

(node as any).hash = "24d362f31f3a50130593201fed5705f3";

export default node;
