/**
 * @generated SignedSource<<61694f7cf0b04e59125362b51cc1a7ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CloudProvider = "AWS" | "Azure" | "GCP" | "Kubernetes" | "TencentCloud" | "%future added value";
export type UpdatePolicyCollectionInput = {
  autoUpdate?: boolean | null | undefined;
  description?: string | null | undefined;
  name?: string | null | undefined;
  provider?: CloudProvider | null | undefined;
  uuid: string;
};
export type UpsertPolicyCollectionMappingsInput = {
  mappings?: ReadonlyArray<PolicyCollectionMappingInput> | null | undefined;
};
export type PolicyCollectionMappingInput = {
  collectionUUID: string;
  policyUUID: string;
  policyVersion: number;
};
export type RemovePolicyCollectionMappingsInput = {
  ids: ReadonlyArray<string>;
};
export type EditPolicyCollectionMutation$variables = {
  connections: ReadonlyArray<string>;
  input: UpdatePolicyCollectionInput;
  removeMappingsInput: RemovePolicyCollectionMappingsInput;
  updateMappingsInput: UpsertPolicyCollectionMappingsInput;
};
export type EditPolicyCollectionMutation$data = {
  readonly removePolicyCollectionMappings: {
    readonly removed: ReadonlyArray<{
      readonly id: string;
    }>;
  };
  readonly updatePolicyCollection: {
    readonly collection: {
      readonly autoUpdate: boolean;
      readonly description: string | null | undefined;
      readonly id: string;
      readonly name: string;
      readonly uuid: string;
    } | null | undefined;
  } | null | undefined;
  readonly upsertPolicyCollectionMappings: {
    readonly mappings: ReadonlyArray<{
      readonly policy: {
        readonly id: string;
        readonly name: string;
      };
    }>;
  };
};
export type EditPolicyCollectionMutation = {
  response: EditPolicyCollectionMutation$data;
  variables: EditPolicyCollectionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "removeMappingsInput"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "updateMappingsInput"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "input",
      "variableName": "input"
    }
  ],
  "concreteType": "PolicyCollectionPayload",
  "kind": "LinkedField",
  "name": "updatePolicyCollection",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PolicyCollection",
      "kind": "LinkedField",
      "name": "collection",
      "plural": false,
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "uuid",
          "storageKey": null
        },
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "autoUpdate",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "removeMappingsInput"
  }
],
v8 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "updateMappingsInput"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Policy",
  "kind": "LinkedField",
  "name": "policy",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditPolicyCollectionMutation",
    "selections": [
      (v6/*: any*/),
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "RemovePolicyCollectionMappingsPayload",
        "kind": "LinkedField",
        "name": "removePolicyCollectionMappings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RemovedNode",
            "kind": "LinkedField",
            "name": "removed",
            "plural": true,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "UpsertPolicyCollectionMappingsPayload",
        "kind": "LinkedField",
        "name": "upsertPolicyCollectionMappings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PolicyCollectionMapping",
            "kind": "LinkedField",
            "name": "mappings",
            "plural": true,
            "selections": [
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "EditPolicyCollectionMutation",
    "selections": [
      (v6/*: any*/),
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "RemovePolicyCollectionMappingsPayload",
        "kind": "LinkedField",
        "name": "removePolicyCollectionMappings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RemovedNode",
            "kind": "LinkedField",
            "name": "removed",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "deleteRecord",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "UpsertPolicyCollectionMappingsPayload",
        "kind": "LinkedField",
        "name": "upsertPolicyCollectionMappings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PolicyCollectionMapping",
            "kind": "LinkedField",
            "name": "mappings",
            "plural": true,
            "selections": [
              (v9/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "mappings",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "PolicyCollectionMappingEdge"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5aaccf55cd7b3d0326fb2a217b814bb0",
    "id": null,
    "metadata": {},
    "name": "EditPolicyCollectionMutation",
    "operationKind": "mutation",
    "text": "mutation EditPolicyCollectionMutation(\n  $input: UpdatePolicyCollectionInput!\n  $updateMappingsInput: UpsertPolicyCollectionMappingsInput!\n  $removeMappingsInput: RemovePolicyCollectionMappingsInput!\n) {\n  updatePolicyCollection(input: $input) {\n    collection {\n      id\n      uuid\n      name\n      description\n      autoUpdate\n    }\n  }\n  removePolicyCollectionMappings(input: $removeMappingsInput) {\n    removed {\n      id\n    }\n  }\n  upsertPolicyCollectionMappings(input: $updateMappingsInput) {\n    mappings {\n      policy {\n        name\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3b41b79f46df252256385b7f9ea4e829";

export default node;
