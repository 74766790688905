/**
 * @generated SignedSource<<b862e6f391aea2cfe424ade2e7beb2fe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type SetSSOGroupConfigsInput = {
  groups: ReadonlyArray<SSOGroupConfigInput>;
};
export type SSOGroupConfigInput = {
  accountGroupUUIDs: ReadonlyArray<string>;
  name: string;
  roles: ReadonlyArray<string>;
};
export type UpdateDefaultRoleInput = {
  role?: string | null | undefined;
};
export type SetSSOGroupsMutation$variables = {
  defaultRoleInput: UpdateDefaultRoleInput;
  input?: SetSSOGroupConfigsInput | null | undefined;
};
export type SetSSOGroupsMutation$data = {
  readonly setSSOGroups: {
    readonly groups: ReadonlyArray<{
      readonly accountGroupUUIDs: ReadonlyArray<string>;
      readonly name: string;
      readonly roles: ReadonlyArray<string>;
    }>;
  };
  readonly updateDefaultRole: string | null | undefined;
};
export type SetSSOGroupsMutation = {
  response: SetSSOGroupsMutation$data;
  variables: SetSSOGroupsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "defaultRoleInput"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SetSSOGroupsPayload",
    "kind": "LinkedField",
    "name": "setSSOGroups",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SSOGroupConfig",
        "kind": "LinkedField",
        "name": "groups",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountGroupUUIDs",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "roles",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "defaultRoleInput"
      }
    ],
    "kind": "ScalarField",
    "name": "updateDefaultRole",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SetSSOGroupsMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SetSSOGroupsMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "2f2c33bafa8ec0b89e274900ce620e58",
    "id": null,
    "metadata": {},
    "name": "SetSSOGroupsMutation",
    "operationKind": "mutation",
    "text": "mutation SetSSOGroupsMutation(\n  $input: SetSSOGroupConfigsInput\n  $defaultRoleInput: UpdateDefaultRoleInput!\n) {\n  setSSOGroups(input: $input) {\n    groups {\n      accountGroupUUIDs\n      name\n      roles\n    }\n  }\n  updateDefaultRole(input: $defaultRoleInput)\n}\n"
  }
};
})();

(node as any).hash = "0a07d51c4b5a544205f9b3e448bcb9b2";

export default node;
