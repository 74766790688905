/**
 * @generated SignedSource<<3d81c570339a041365cdae79070cbbe6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type UpdateRepositoryInput = {
  authToken?: string | null | undefined;
  authUser?: string | null | undefined;
  branchName?: string | null | undefined;
  description?: string | null | undefined;
  name?: string | null | undefined;
  policyDirectories?: ReadonlyArray<string> | null | undefined;
  policyFileSuffix?: ReadonlyArray<string> | null | undefined;
  sshPassphrase?: string | null | undefined;
  sshPrivateKey?: string | null | undefined;
  url: string;
};
export type UpdateRepositoryMutation$variables = {
  input: UpdateRepositoryInput;
};
export type UpdateRepositoryMutation$data = {
  readonly updateRepository: {
    readonly repository: {
      readonly authUser: string | null | undefined;
      readonly branchName: string | null | undefined;
      readonly description: string | null | undefined;
      readonly hasAuthToken: boolean | null | undefined;
      readonly hasSshPassphrase: boolean | null | undefined;
      readonly hasSshPrivateKey: boolean | null | undefined;
      readonly name: string;
      readonly policyDirectories: ReadonlyArray<string> | null | undefined;
      readonly policyFileSuffix: ReadonlyArray<string> | null | undefined;
      readonly url: string;
    } | null | undefined;
  } | null | undefined;
};
export type UpdateRepositoryMutation = {
  response: UpdateRepositoryMutation$data;
  variables: UpdateRepositoryMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "authUser",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "branchName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "policyDirectories",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "policyFileSuffix",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasAuthToken",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasSshPassphrase",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasSshPrivateKey",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateRepositoryMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RepositoryPayload",
        "kind": "LinkedField",
        "name": "updateRepository",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Repository",
            "kind": "LinkedField",
            "name": "repository",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateRepositoryMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RepositoryPayload",
        "kind": "LinkedField",
        "name": "updateRepository",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Repository",
            "kind": "LinkedField",
            "name": "repository",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ceea95a0bd031eb43363a8625b353fdb",
    "id": null,
    "metadata": {},
    "name": "UpdateRepositoryMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateRepositoryMutation(\n  $input: UpdateRepositoryInput!\n) {\n  updateRepository(input: $input) {\n    repository {\n      name\n      url\n      authUser\n      branchName\n      description\n      policyDirectories\n      policyFileSuffix\n      hasAuthToken\n      hasSshPassphrase\n      hasSshPrivateKey\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fbd2dbaa623abac6b23aabceb5063529";

export default node;
