/**
 * @generated SignedSource<<62ecab7617a8f17aea36ad168699bd2a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DiscoverAccountsQuery$variables = {
  skip: boolean;
  uuid: string;
};
export type DiscoverAccountsQuery$data = {
  readonly accountScan?: {
    readonly added: number | null | undefined;
    readonly completed: string | null | undefined;
    readonly error: string | null | undefined;
  } | null | undefined;
};
export type DiscoverAccountsQuery = {
  response: DiscoverAccountsQuery$data;
  variables: DiscoverAccountsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skip"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "uuid"
},
v2 = [
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "uuid"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completed",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "error",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "added",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DiscoverAccountsQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "AccountScan",
            "kind": "LinkedField",
            "name": "accountScan",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "DiscoverAccountsQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "AccountScan",
            "kind": "LinkedField",
            "name": "accountScan",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "8838e581e5492f15f36d6fedf80f3d95",
    "id": null,
    "metadata": {},
    "name": "DiscoverAccountsQuery",
    "operationKind": "query",
    "text": "query DiscoverAccountsQuery(\n  $uuid: String!\n  $skip: Boolean!\n) {\n  accountScan(uuid: $uuid) @skip(if: $skip) {\n    completed\n    error\n    added\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "41ec3d486928a9cccf98ccef86d9e8ef";

export default node;
