/**
 * @generated SignedSource<<23557e248bd9b7952f84b986d037dfa6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type UpdateSlackProfileInput = {
  name: string;
  scope: string;
  token?: string | null | undefined;
  userFields?: ReadonlyArray<string> | null | undefined;
  webhooks?: ReadonlyArray<UpdateSlackWebhookInput> | null | undefined;
};
export type UpdateSlackWebhookInput = {
  name: string;
  url?: string | null | undefined;
};
export type UpdateSlackProfileMutation$variables = {
  input: UpdateSlackProfileInput;
};
export type UpdateSlackProfileMutation$data = {
  readonly updateSlackProfile: {
    readonly configuration: {
      readonly profile: string;
      readonly record: {
        readonly token?: string | null | undefined;
        readonly webhooks?: ReadonlyArray<{
          readonly name: string;
          readonly url: string;
        }> | null | undefined;
      };
    };
  } | null | undefined;
};
export type UpdateSlackProfileMutation = {
  response: UpdateSlackProfileMutation$data;
  variables: UpdateSlackProfileMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profile",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "token",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SlackWebhook",
      "kind": "LinkedField",
      "name": "webhooks",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SlackConfiguration",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateSlackProfileMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ConfigurationPayload",
        "kind": "LinkedField",
        "name": "updateSlackProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Configuration",
            "kind": "LinkedField",
            "name": "configuration",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "record",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateSlackProfileMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ConfigurationPayload",
        "kind": "LinkedField",
        "name": "updateSlackProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Configuration",
            "kind": "LinkedField",
            "name": "configuration",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "record",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c9d0f76df1f09dd012fc1864b747e4cf",
    "id": null,
    "metadata": {},
    "name": "UpdateSlackProfileMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateSlackProfileMutation(\n  $input: UpdateSlackProfileInput!\n) {\n  updateSlackProfile(input: $input) {\n    configuration {\n      profile\n      record {\n        __typename\n        ... on SlackConfiguration {\n          token\n          webhooks {\n            name\n            url\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5dde5e9fbdd1eb303fcc039b853f5a7d";

export default node;
