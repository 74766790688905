/**
 * @generated SignedSource<<4185c0198fd90777c6e6f68301d4eff2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type SandboxQuery$variables = Record<PropertyKey, never>;
export type SandboxQuery$data = {
  readonly Deployment: {
    readonly gqlUri: string | null | undefined;
  } | null | undefined;
};
export type SandboxQuery = {
  response: SandboxQuery$data;
  variables: SandboxQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "ClientExtension",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Deployment",
        "kind": "LinkedField",
        "name": "Deployment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gqlUri",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SandboxQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SandboxQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "19915373be5272f6150e42faca08c5d6",
    "id": null,
    "metadata": {},
    "name": "SandboxQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "eb67618f2ac6c1dd415d9c38248bfcfa";

export default node;
