/**
 * @generated SignedSource<<32bd71786fd4e393bbb738dc238248f2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserContext_whoami$data = {
  readonly whoami: {
    readonly permissions: ReadonlyArray<{
      readonly action: string;
      readonly object: string;
    }>;
  };
  readonly " $fragmentType": "UserContext_whoami";
};
export type UserContext_whoami$key = {
  readonly " $data"?: UserContext_whoami$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserContext_whoami">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserContext_whoami",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CurrentUser",
      "kind": "LinkedField",
      "name": "whoami",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Permission",
          "kind": "LinkedField",
          "name": "permissions",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "object",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "action",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "ec4420eb46dcbddf9fe3a8c72392204f";

export default node;
