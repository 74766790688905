/**
 * @generated SignedSource<<22073c2912665d0a77c7fd4b213b602b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ExportFormat = "CSV" | "%future added value";
export type ExportConnectionInput = {
  columns: ReadonlyArray<ExportColumnInput>;
  extraPaths?: ReadonlyArray<string> | null | undefined;
  field: string;
  filename?: string | null | undefined;
  format: ExportFormat;
  node?: string | null | undefined;
  params?: ReadonlyArray<ExportParamInput> | null | undefined;
};
export type ExportParamInput = {
  name: string;
  type: string;
  valueJSON: string;
};
export type ExportColumnInput = {
  name: string;
  path: string;
  subpath?: string | null | undefined;
};
export type ExportConnectionMutation$variables = {
  input: ExportConnectionInput;
};
export type ExportConnectionMutation$data = {
  readonly exportConnection: {
    readonly export: {
      readonly id: string;
    };
  } | null | undefined;
};
export type ExportConnectionMutation = {
  response: ExportConnectionMutation$data;
  variables: ExportConnectionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ExportConnectionPayload",
    "kind": "LinkedField",
    "name": "exportConnection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ConnectionExport",
        "kind": "LinkedField",
        "name": "export",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExportConnectionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExportConnectionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ee957f5cc047b0615e8bd4de1deeaf5e",
    "id": null,
    "metadata": {},
    "name": "ExportConnectionMutation",
    "operationKind": "mutation",
    "text": "mutation ExportConnectionMutation(\n  $input: ExportConnectionInput!\n) {\n  exportConnection(input: $input) {\n    export {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "677597acb40511190371a988fec3df9c";

export default node;
