/**
 * @generated SignedSource<<5265c0e91c0712743beac1c430ed3fa1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FilterModal_filters$data = {
  readonly filters: ReadonlyArray<{
    readonly name: string;
    readonly suggestions: ReadonlyArray<string> | null | undefined;
  }>;
  readonly " $fragmentType": "FilterModal_filters";
};
export type FilterModal_filters$key = {
  readonly " $data"?: FilterModal_filters$data;
  readonly " $fragmentSpreads": FragmentRefs<"FilterModal_filters">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FilterModal_filters",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Filter",
      "kind": "LinkedField",
      "name": "filters",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "suggestions",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "FilterSchema",
  "abstractKey": null
};

(node as any).hash = "bacaa528fe0d285d7b5ba3823fdfd20a";

export default node;
