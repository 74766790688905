/**
 * @generated SignedSource<<c445ec4bf71a24956d2c62a5d2d10572>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CloudProvider = "AWS" | "Azure" | "GCP" | "Kubernetes" | "TencentCloud" | "%future added value";
export type ValidateAccountInput = {
  key: string;
  provider: CloudProvider;
};
export type ValidateAccountMutation$variables = {
  input: ValidateAccountInput;
};
export type ValidateAccountMutation$data = {
  readonly validateAccount: {
    readonly account: {
      readonly email: string | null | undefined;
      readonly id: string;
      readonly key: string;
      readonly name: string;
      readonly path: string | null | undefined;
      readonly provider: CloudProvider;
      readonly securityContext: string;
      readonly status: boolean | null | undefined;
      readonly status_message: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type ValidateAccountMutation = {
  response: ValidateAccountMutation$data;
  variables: ValidateAccountMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AccountPayload",
    "kind": "LinkedField",
    "name": "validateAccount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "provider",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "key",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "path",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "securityContext",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status_message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ValidateAccountMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ValidateAccountMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "cf2c914d0ad5ad72da4dc054881be701",
    "id": null,
    "metadata": {},
    "name": "ValidateAccountMutation",
    "operationKind": "mutation",
    "text": "mutation ValidateAccountMutation(\n  $input: ValidateAccountInput!\n) {\n  validateAccount(input: $input) {\n    account {\n      id\n      name\n      provider\n      key\n      path\n      email\n      securityContext\n      status\n      status_message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bbdcd27d91e5d0c6944048d0fccdeee1";

export default node;
