/**
 * @generated SignedSource<<489f1a795cd135f802262a28a5e1c9fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountGroupAutocomplete_accountGroups$data = {
  readonly accountGroups: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly name: string;
      };
    }>;
  };
  readonly " $fragmentType": "AccountGroupAutocomplete_accountGroups";
};
export type AccountGroupAutocomplete_accountGroups$key = {
  readonly " $data"?: AccountGroupAutocomplete_accountGroups$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountGroupAutocomplete_accountGroups">;
};

import AccountGroupAutocompleteRefetchQuery_graphql from './AccountGroupAutocompleteRefetchQuery.graphql';

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "filterElement"
    },
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": AccountGroupAutocompleteRefetchQuery_graphql
    }
  },
  "name": "AccountGroupAutocomplete_accountGroups",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filterElement",
          "variableName": "filterElement"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        }
      ],
      "concreteType": "AccountGroupConnection",
      "kind": "LinkedField",
      "name": "accountGroups",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AccountGroupEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AccountGroup",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "ed031d023cce1ec85cf1c2abab0fce12";

export default node;
