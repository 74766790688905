/**
 * @generated SignedSource<<2706fc5ddfb99799daa1ff70de4f98d1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Remediations_recommendedActions$data = {
  readonly recommendedActions: string | null | undefined;
  readonly " $fragmentType": "Remediations_recommendedActions";
};
export type Remediations_recommendedActions$key = {
  readonly " $data"?: Remediations_recommendedActions$data;
  readonly " $fragmentSpreads": FragmentRefs<"Remediations_recommendedActions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Remediations_recommendedActions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recommendedActions",
      "storageKey": null
    }
  ],
  "type": "DashboardSection",
  "abstractKey": null
};

(node as any).hash = "37554a33071347b9c7e34ea87591ae99";

export default node;
