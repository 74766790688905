/**
 * @generated SignedSource<<10c6ade1fa9aa151a4572bc7b17b822c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type bootstrapDeploymentQuery$variables = Record<PropertyKey, never>;
export type bootstrapDeploymentQuery$data = {
  readonly Deployment: {
    readonly __typename: "Deployment";
  } | null | undefined;
  readonly platform: {
    readonly __typename: "Platform";
  };
};
export type bootstrapDeploymentQuery = {
  response: bootstrapDeploymentQuery$data;
  variables: bootstrapDeploymentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Deployment",
      "kind": "LinkedField",
      "name": "Deployment",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "bootstrapDeploymentQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Platform",
        "kind": "LinkedField",
        "name": "platform",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      (v2/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "bootstrapDeploymentQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Platform",
        "kind": "LinkedField",
        "name": "platform",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v2/*: any*/)
    ]
  },
  "params": {
    "cacheID": "77d5bddd8bbd5340359718deed0be880",
    "id": null,
    "metadata": {},
    "name": "bootstrapDeploymentQuery",
    "operationKind": "query",
    "text": "query bootstrapDeploymentQuery {\n  platform {\n    __typename\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "7e7aedcef942a85f43d00b4161b03a6b";

export default node;
