/**
 * @generated SignedSource<<418ceb86c4c697c543cf50544c24f5e3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Actions_policy$data = {
  readonly source: string;
  readonly " $fragmentType": "Actions_policy";
};
export type Actions_policy$key = {
  readonly " $data"?: Actions_policy$data;
  readonly " $fragmentSpreads": FragmentRefs<"Actions_policy">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Actions_policy",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "source",
      "storageKey": null
    }
  ],
  "type": "Policy",
  "abstractKey": null
};

(node as any).hash = "193a88ccce036c6f2e406fc1f732a5f1";

export default node;
