/**
 * @generated SignedSource<<4755c422f4696a81076bf2de781ea811>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type RemoveTemplateMutation$variables = {
  id: string;
};
export type RemoveTemplateMutation$data = {
  readonly removeTemplate: string | null | undefined;
};
export type RemoveTemplateMutation = {
  response: RemoveTemplateMutation$data;
  variables: RemoveTemplateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": (v1/*: any*/),
  "kind": "ScalarField",
  "name": "removeTemplate",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveTemplateMutation",
    "selections": [
      (v2/*: any*/)
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveTemplateMutation",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": null,
        "handle": "deleteRecord",
        "key": "",
        "kind": "ScalarHandle",
        "name": "removeTemplate"
      }
    ]
  },
  "params": {
    "cacheID": "aab24e1aea08a86c6ceebe5367833146",
    "id": null,
    "metadata": {},
    "name": "RemoveTemplateMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveTemplateMutation(\n  $id: String!\n) {\n  removeTemplate(id: $id)\n}\n"
  }
};
})();

(node as any).hash = "5d552e62650cb7a1404365e185fb1317";

export default node;
