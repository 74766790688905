/**
 * @generated SignedSource<<95721b643b09c0f1e1e9fa48f6966554>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type RemoveReportGroupsMutation$variables = {
  connections: ReadonlyArray<string>;
  names: ReadonlyArray<string>;
};
export type RemoveReportGroupsMutation$data = {
  readonly removeReportGroups: ReadonlyArray<string>;
};
export type RemoveReportGroupsMutation = {
  response: RemoveReportGroupsMutation$data;
  variables: RemoveReportGroupsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "names"
},
v2 = [
  {
    "kind": "Variable",
    "name": "names",
    "variableName": "names"
  }
],
v3 = {
  "alias": null,
  "args": (v2/*: any*/),
  "kind": "ScalarField",
  "name": "removeReportGroups",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveReportGroupsMutation",
    "selections": [
      (v3/*: any*/)
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "RemoveReportGroupsMutation",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": (v2/*: any*/),
        "filters": null,
        "handle": "deleteEdge",
        "key": "",
        "kind": "ScalarHandle",
        "name": "removeReportGroups",
        "handleArgs": [
          {
            "kind": "Variable",
            "name": "connections",
            "variableName": "connections"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "400ada1746b75e9efc5da6ecf017d3ab",
    "id": null,
    "metadata": {},
    "name": "RemoveReportGroupsMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveReportGroupsMutation(\n  $names: [String!]!\n) {\n  removeReportGroups(names: $names)\n}\n"
  }
};
})();

(node as any).hash = "84018a95c304e97fcb23a4fcc55cf7b6";

export default node;
