/**
 * @generated SignedSource<<0ea27358bdb84d2a76b4336d11d80afe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CloudProvider = "AWS" | "Azure" | "GCP" | "Kubernetes" | "TencentCloud" | "%future added value";
export type UpdateAccountInput = {
  description?: string | null | undefined;
  email?: string | null | undefined;
  key: string;
  name?: string | null | undefined;
  provider: CloudProvider;
  securityContext?: string | null | undefined;
  shortName?: string | null | undefined;
  tags?: ReadonlyArray<TagInput> | null | undefined;
  variables?: string | null | undefined;
};
export type TagInput = {
  key: string;
  value?: string | null | undefined;
};
export type UpdateAccountMutation$variables = {
  input: UpdateAccountInput;
};
export type UpdateAccountMutation$data = {
  readonly updateAccount: {
    readonly account: {
      readonly credentialInfo: {
        readonly clientID?: string;
        readonly keyID?: string;
        readonly tenantID?: string;
      } | null | undefined;
      readonly email: string | null | undefined;
      readonly id: string;
      readonly key: string;
      readonly name: string;
      readonly path: string | null | undefined;
      readonly provider: CloudProvider;
      readonly securityContext: string;
      readonly status: boolean | null | undefined;
      readonly status_message: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type UpdateAccountMutation = {
  response: UpdateAccountMutation$data;
  variables: UpdateAccountMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "provider",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "path",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "securityContext",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status_message",
  "storageKey": null
},
v11 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tenantID",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientID",
      "storageKey": null
    }
  ],
  "type": "AzureCredentialInfo",
  "abstractKey": null
},
v12 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "keyID",
      "storageKey": null
    }
  ],
  "type": "TencentCloudCredentialInfo",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateAccountMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AccountPayload",
        "kind": "LinkedField",
        "name": "updateAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "credentialInfo",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateAccountMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AccountPayload",
        "kind": "LinkedField",
        "name": "updateAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "credentialInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v11/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0ac543b5d1be5c80259ebe6d2369943b",
    "id": null,
    "metadata": {},
    "name": "UpdateAccountMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateAccountMutation(\n  $input: UpdateAccountInput!\n) {\n  updateAccount(input: $input) {\n    account {\n      id\n      name\n      provider\n      key\n      path\n      email\n      securityContext\n      status\n      status_message\n      credentialInfo {\n        __typename\n        ... on AzureCredentialInfo {\n          tenantID\n          clientID\n        }\n        ... on TencentCloudCredentialInfo {\n          keyID\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "81780d8dde82216b609f0b27668a9472";

export default node;
