/**
 * @generated SignedSource<<0644074092e8ca72a90264e6740680b2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SectionModalResources_dashboard$data = {
  readonly key: string;
  readonly metric: string | null | undefined;
  readonly section: {
    readonly key: string;
    readonly title: string;
  } | null | undefined;
  readonly title: string;
  readonly " $fragmentType": "SectionModalResources_dashboard";
};
export type SectionModalResources_dashboard$key = {
  readonly " $data"?: SectionModalResources_dashboard$data;
  readonly " $fragmentSpreads": FragmentRefs<"SectionModalResources_dashboard">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "sectionKey"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SectionModalResources_dashboard",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "metric",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "sectionKey",
          "variableName": "sectionKey"
        }
      ],
      "concreteType": "DashboardSection",
      "kind": "LinkedField",
      "name": "section",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    (v1/*: any*/)
  ],
  "type": "Dashboard",
  "abstractKey": null
};
})();

(node as any).hash = "e18517f98ff1cdbe885c78eed39b2b0e";

export default node;
