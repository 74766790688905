/**
 * @generated SignedSource<<3dc1ec5658ecf18aa5f9447ba76c948b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CloudProvider = "AWS" | "Azure" | "GCP" | "Kubernetes" | "TencentCloud" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PerSection_dashboard$data = {
  readonly flattenedSections: ReadonlyArray<{
    readonly path: ReadonlyArray<string>;
    readonly title: string;
  }> | null | undefined;
  readonly key: string;
  readonly providers: ReadonlyArray<CloudProvider> | null | undefined;
  readonly title: string;
  readonly " $fragmentType": "PerSection_dashboard";
};
export type PerSection_dashboard$key = {
  readonly " $data"?: PerSection_dashboard$data;
  readonly " $fragmentSpreads": FragmentRefs<"PerSection_dashboard">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PerSection_dashboard",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "key",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "providers",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DashboardSection",
      "kind": "LinkedField",
      "name": "flattenedSections",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Dashboard",
  "abstractKey": null
};
})();

(node as any).hash = "5f6c0f24355bae10d9ae60fad2a6d52e";

export default node;
