/**
 * @generated SignedSource<<c88417cf126ca3d23886bdcbcf28d31b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CloudProvider = "AWS" | "Azure" | "GCP" | "Kubernetes" | "TencentCloud" | "%future added value";
export type ExecutionDetailsQuery$variables = {
  uuid: string;
};
export type ExecutionDetailsQuery$data = {
  readonly execution: {
    readonly account: {
      readonly key: string;
      readonly provider: CloudProvider;
    };
    readonly end: string | null | undefined;
    readonly event: string | null | undefined;
    readonly issues: ReadonlyArray<string> | null | undefined;
    readonly metricApiCalls: number | null | undefined;
    readonly metricException: boolean | null | undefined;
    readonly metricResources: number | null | undefined;
    readonly paramDryrun: boolean | null | undefined;
    readonly paramRegion: string | null | undefined;
    readonly policy: {
      readonly name: string;
      readonly uuid: string;
      readonly version: number;
    };
    readonly start: string;
    readonly status: string;
    readonly uuid: string;
    readonly " $fragmentSpreads": FragmentRefs<"ExecutionLog_log">;
  } | null | undefined;
};
export type ExecutionDetailsQuery = {
  response: ExecutionDetailsQuery$data;
  variables: ExecutionDetailsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "uuid"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "uuid"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "event",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "end",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paramRegion",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "metricResources",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "metricApiCalls",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "metricException",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paramDryrun",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issues",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "version",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "provider",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExecutionDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PolicyExecution",
        "kind": "LinkedField",
        "name": "execution",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Policy",
            "kind": "LinkedField",
            "name": "policy",
            "plural": false,
            "selections": [
              (v13/*: any*/),
              (v3/*: any*/),
              (v14/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v15/*: any*/),
              (v16/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ExecutionLog_log"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExecutionDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PolicyExecution",
        "kind": "LinkedField",
        "name": "execution",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Policy",
            "kind": "LinkedField",
            "name": "policy",
            "plural": false,
            "selections": [
              (v13/*: any*/),
              (v3/*: any*/),
              (v14/*: any*/),
              (v17/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "log",
            "storageKey": null
          },
          (v17/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ff32d5239b594b8388b4c7ae7b652174",
    "id": null,
    "metadata": {},
    "name": "ExecutionDetailsQuery",
    "operationKind": "query",
    "text": "query ExecutionDetailsQuery(\n  $uuid: String!\n) {\n  execution(uuid: $uuid) {\n    event\n    uuid\n    start\n    end\n    paramRegion\n    metricResources\n    metricApiCalls\n    metricException\n    paramDryrun\n    status\n    issues\n    policy {\n      name\n      uuid\n      version\n      id\n    }\n    account {\n      key\n      provider\n      id\n    }\n    ...ExecutionLog_log\n    id\n  }\n}\n\nfragment ExecutionLog_log on PolicyExecution {\n  log\n}\n"
  }
};
})();

(node as any).hash = "a482b5fe95dea562914dbea507bc6711";

export default node;
