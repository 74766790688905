/**
 * @generated SignedSource<<a7de99638fcd9aa739aa30e799013768>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ResourceTagAutocomplete_resourceTags$data = {
  readonly resourceTagKeys: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly key: string;
      };
    }>;
  };
  readonly " $fragmentType": "ResourceTagAutocomplete_resourceTags";
};
export type ResourceTagAutocomplete_resourceTags$key = {
  readonly " $data"?: ResourceTagAutocomplete_resourceTags$data;
  readonly " $fragmentSpreads": FragmentRefs<"ResourceTagAutocomplete_resourceTags">;
};

import ResourceTagAutocompleteRefetchQuery_graphql from './ResourceTagAutocompleteRefetchQuery.graphql';

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterElement"
    },
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": ResourceTagAutocompleteRefetchQuery_graphql
    }
  },
  "name": "ResourceTagAutocomplete_resourceTags",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filterElement",
          "variableName": "filterElement"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        }
      ],
      "concreteType": "ResourceTagKeyConnection",
      "kind": "LinkedField",
      "name": "resourceTagKeys",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ResourceTagKeyEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ResourceTagKey",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "key",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "9d4c4345977592555245aff78ffe6c72";

export default node;
