/**
 * @generated SignedSource<<4381ff6e0a1cc52eac8ccc5d62cc05ef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CloudProvider = "AWS" | "Azure" | "GCP" | "Kubernetes" | "TencentCloud" | "%future added value";
export type AccountInput = {
  description?: string | null | undefined;
  email?: string | null | undefined;
  key: string;
  name: string;
  path?: string | null | undefined;
  provider: CloudProvider;
  securityContext: string;
  shortName?: string | null | undefined;
  tags?: ReadonlyArray<TagInput> | null | undefined;
  variables?: string | null | undefined;
};
export type TagInput = {
  key: string;
  value?: string | null | undefined;
};
export type AddAccountMutation$variables = {
  connections: ReadonlyArray<string>;
  input: AccountInput;
};
export type AddAccountMutation$data = {
  readonly addAccount: {
    readonly account: {
      readonly email: string | null | undefined;
      readonly id: string;
      readonly key: string;
      readonly name: string;
      readonly path: string | null | undefined;
      readonly provider: CloudProvider;
      readonly securityContext: string;
      readonly status: boolean | null | undefined;
      readonly status_message: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type AddAccountMutation = {
  response: AddAccountMutation$data;
  variables: AddAccountMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Account",
  "kind": "LinkedField",
  "name": "account",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "provider",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "key",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "path",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "securityContext",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status_message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddAccountMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AccountPayload",
        "kind": "LinkedField",
        "name": "addAccount",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddAccountMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AccountPayload",
        "kind": "LinkedField",
        "name": "addAccount",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "account",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "AccountEdge"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b15c3e768e5d5e2d25d1ec681a39bb2a",
    "id": null,
    "metadata": {},
    "name": "AddAccountMutation",
    "operationKind": "mutation",
    "text": "mutation AddAccountMutation(\n  $input: AccountInput!\n) {\n  addAccount(input: $input) {\n    account {\n      id\n      name\n      provider\n      key\n      path\n      email\n      securityContext\n      status\n      status_message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "78f8ade10e79bb5a2a997626bd5f40db";

export default node;
