/**
 * @generated SignedSource<<860eaea1ead31b9b95a8f9c8a8ae3f6f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CloudProvider = "AWS" | "Azure" | "GCP" | "Kubernetes" | "TencentCloud" | "%future added value";
export type UpdatePolicyCollectionInput = {
  autoUpdate?: boolean | null | undefined;
  description?: string | null | undefined;
  name?: string | null | undefined;
  provider?: CloudProvider | null | undefined;
  uuid: string;
};
export type UpdatePolicyCollectionMutation$variables = {
  input: UpdatePolicyCollectionInput;
};
export type UpdatePolicyCollectionMutation$data = {
  readonly updatePolicyCollection: {
    readonly collection: {
      readonly autoUpdate: boolean;
      readonly description: string | null | undefined;
      readonly id: string;
      readonly items: ReadonlyArray<{
        readonly name: string;
        readonly uuid: string;
        readonly version: number;
      }> | null | undefined;
      readonly name: string;
      readonly uuid: string;
    } | null | undefined;
  } | null | undefined;
};
export type UpdatePolicyCollectionMutation = {
  response: UpdatePolicyCollectionMutation$data;
  variables: UpdatePolicyCollectionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PolicyCollectionPayload",
    "kind": "LinkedField",
    "name": "updatePolicyCollection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PolicyCollection",
        "kind": "LinkedField",
        "name": "collection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "autoUpdate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PolicyCollectionItem",
            "kind": "LinkedField",
            "name": "items",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "version",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdatePolicyCollectionMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdatePolicyCollectionMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "768979ce0642fa7905540a3a850e5755",
    "id": null,
    "metadata": {},
    "name": "UpdatePolicyCollectionMutation",
    "operationKind": "mutation",
    "text": "mutation UpdatePolicyCollectionMutation(\n  $input: UpdatePolicyCollectionInput!\n) {\n  updatePolicyCollection(input: $input) {\n    collection {\n      id\n      uuid\n      name\n      description\n      autoUpdate\n      items {\n        uuid\n        name\n        version\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f4c0410cb01303bb6ccff9abcc498bfb";

export default node;
