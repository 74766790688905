/**
 * @generated SignedSource<<6cbe44c2c03d1d3bb2ea3e18eb96393d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ExternalLinksQuery$variables = Record<PropertyKey, never>;
export type ExternalLinksQuery$data = {
  readonly UrlConfig: {
    readonly docs: string | null | undefined;
  } | null | undefined;
};
export type ExternalLinksQuery = {
  response: ExternalLinksQuery$data;
  variables: ExternalLinksQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "ClientExtension",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UrlConfig",
        "kind": "LinkedField",
        "name": "UrlConfig",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "docs",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ExternalLinksQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ExternalLinksQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "533b4e1ff82cfba55cd88b468b27f1b7",
    "id": null,
    "metadata": {},
    "name": "ExternalLinksQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "acc5f9237798d8bdb9dbbc6da50daaef";

export default node;
