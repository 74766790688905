/**
 * @generated SignedSource<<329347cf0813dcf70763509f5da9f277>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CloudProvider = "AWS" | "Azure" | "GCP" | "Kubernetes" | "TencentCloud" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Timeline_dashboard$data = {
  readonly key: string;
  readonly providers: ReadonlyArray<CloudProvider> | null | undefined;
  readonly " $fragmentType": "Timeline_dashboard";
};
export type Timeline_dashboard$key = {
  readonly " $data"?: Timeline_dashboard$data;
  readonly " $fragmentSpreads": FragmentRefs<"Timeline_dashboard">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Timeline_dashboard",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "key",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "providers",
      "storageKey": null
    }
  ],
  "type": "Dashboard",
  "abstractKey": null
};

(node as any).hash = "7f24d1e505e8791d4aa4d3046b85d12f";

export default node;
