/**
 * @generated SignedSource<<a2a1e9cd1fca762f8f2bfe7e3532b58c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type SideBarQuery$variables = Record<PropertyKey, never>;
export type SideBarQuery$data = {
  readonly UrlConfig: {
    readonly jun0: string | null | undefined;
    readonly redash: string | null | undefined;
    readonly sinistral: string | null | undefined;
  } | null | undefined;
};
export type SideBarQuery = {
  response: SideBarQuery$data;
  variables: SideBarQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "ClientExtension",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UrlConfig",
        "kind": "LinkedField",
        "name": "UrlConfig",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "jun0",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "redash",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sinistral",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SideBarQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SideBarQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "0435f8f23b9007817b30bae417719b8d",
    "id": null,
    "metadata": {},
    "name": "SideBarQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "2e1336d0c3009eacce0d0498788dd8c8";

export default node;
