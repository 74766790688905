/**
 * @generated SignedSource<<321644357e6ea43adb2b15aa984ca793>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type RunBindingInput = {
  uuid: string;
};
export type RunBindingMutation$variables = {
  input: RunBindingInput;
};
export type RunBindingMutation$data = {
  readonly runBinding: {
    readonly binding: {
      readonly name: string;
      readonly uuid: string;
    };
  } | null | undefined;
};
export type RunBindingMutation = {
  response: RunBindingMutation$data;
  variables: RunBindingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RunBindingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RunBindingPayload",
        "kind": "LinkedField",
        "name": "runBinding",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Binding",
            "kind": "LinkedField",
            "name": "binding",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RunBindingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RunBindingPayload",
        "kind": "LinkedField",
        "name": "runBinding",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Binding",
            "kind": "LinkedField",
            "name": "binding",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d8d7b865261269c1c9e63990cb19965b",
    "id": null,
    "metadata": {},
    "name": "RunBindingMutation",
    "operationKind": "mutation",
    "text": "mutation RunBindingMutation(\n  $input: RunBindingInput!\n) {\n  runBinding(input: $input) {\n    binding {\n      name\n      uuid\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6f51a4b9889314de44038279164250ff";

export default node;
