/**
 * @generated SignedSource<<e62441cf5e79b8b286deb3518f3ac86a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DashboardDescription_dashboard$data = {
  readonly description: string | null | undefined;
  readonly " $fragmentType": "DashboardDescription_dashboard";
};
export type DashboardDescription_dashboard$key = {
  readonly " $data"?: DashboardDescription_dashboard$data;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardDescription_dashboard">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DashboardDescription_dashboard",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "type": "Dashboard",
  "abstractKey": null
};

(node as any).hash = "f0383a7c31dd25301136900598d48826";

export default node;
