/**
 * @generated SignedSource<<4e5915f0a36d547e5d191ad161ba19a6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DeployBindingsQuery$variables = Record<PropertyKey, never>;
export type DeployBindingsQuery$data = {
  readonly bindings: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly name: string;
        readonly percentageDeployed: number;
        readonly uuid: string;
      };
    }>;
  };
};
export type DeployBindingsQuery = {
  response: DeployBindingsQuery$data;
  variables: DeployBindingsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "percentageDeployed",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DeployBindingsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BindingConnection",
        "kind": "LinkedField",
        "name": "bindings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BindingEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Binding",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DeployBindingsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BindingConnection",
        "kind": "LinkedField",
        "name": "bindings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BindingEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Binding",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "66b8da3ee7c2d5471e0c7384189b3f63",
    "id": null,
    "metadata": {},
    "name": "DeployBindingsQuery",
    "operationKind": "query",
    "text": "query DeployBindingsQuery {\n  bindings {\n    edges {\n      node {\n        name\n        uuid\n        percentageDeployed\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "484ffdfcc808b9ecc46e114fcfcdd3d7";

export default node;
