/**
 * @generated SignedSource<<a71d2fe3c66815cec137a8aa9fa5db8a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type SendTestMessageInput = {
  profile: string;
  recipient: string;
  scope?: string | null | undefined;
  template?: string | null | undefined;
};
export type SendTestMessageMutation$variables = {
  input: SendTestMessageInput;
};
export type SendTestMessageMutation$data = {
  readonly sendTestMessage: {
    readonly message: string | null | undefined;
    readonly success: boolean;
  };
};
export type SendTestMessageMutation = {
  response: SendTestMessageMutation$data;
  variables: SendTestMessageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SendTestMessagePayload",
    "kind": "LinkedField",
    "name": "sendTestMessage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SendTestMessageMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SendTestMessageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a3cf2ec6def173f877d18d484137cb96",
    "id": null,
    "metadata": {},
    "name": "SendTestMessageMutation",
    "operationKind": "mutation",
    "text": "mutation SendTestMessageMutation(\n  $input: SendTestMessageInput!\n) {\n  sendTestMessage(input: $input) {\n    message\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "927ed827a24dd89223a4a68e69e159b1";

export default node;
