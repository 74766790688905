/**
 * @generated SignedSource<<6238ce70bebc917e4aa191c5858381d3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BreadcrumbsHeaderQuery$variables = Record<PropertyKey, never>;
export type BreadcrumbsHeaderQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Support_whoami">;
};
export type BreadcrumbsHeaderQuery = {
  response: BreadcrumbsHeaderQuery$data;
  variables: BreadcrumbsHeaderQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BreadcrumbsHeaderQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "Support_whoami"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "BreadcrumbsHeaderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CurrentUser",
        "kind": "LinkedField",
        "name": "whoami",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3d03fef59794fa8e620fe610c8bd4366",
    "id": null,
    "metadata": {},
    "name": "BreadcrumbsHeaderQuery",
    "operationKind": "query",
    "text": "query BreadcrumbsHeaderQuery {\n  ...Support_whoami\n}\n\nfragment Support_whoami on Query {\n  whoami {\n    email\n    displayName\n  }\n}\n"
  }
};

(node as any).hash = "185edaeea9cde5e0d5aa30966bd8a25a";

export default node;
