/**
 * @generated SignedSource<<93d6c2d58285b57d237df6acfaac8fa9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CloudProvider = "AWS" | "Azure" | "GCP" | "Kubernetes" | "TencentCloud" | "%future added value";
export type AddPolicyCollectionInput = {
  autoUpdate?: boolean | null | undefined;
  description?: string | null | undefined;
  items?: ReadonlyArray<PolicyCollectionElement> | null | undefined;
  name: string;
  provider: CloudProvider;
};
export type PolicyCollectionElement = {
  policyUUID: string;
  policyVersion?: number | null | undefined;
};
export type AddPolicyCollectionMutation$variables = {
  connections: ReadonlyArray<string>;
  input: AddPolicyCollectionInput;
};
export type AddPolicyCollectionMutation$data = {
  readonly addPolicyCollection: {
    readonly collection: {
      readonly autoUpdate: boolean;
      readonly description: string | null | undefined;
      readonly id: string;
      readonly items: ReadonlyArray<{
        readonly name: string;
        readonly uuid: string;
        readonly version: number;
      }> | null | undefined;
      readonly name: string;
      readonly policyMappings: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly policy: {
              readonly name: string;
            };
          };
        }>;
      };
      readonly provider: CloudProvider | null | undefined;
      readonly uuid: string;
    } | null | undefined;
  } | null | undefined;
};
export type AddPolicyCollectionMutation = {
  response: AddPolicyCollectionMutation$data;
  variables: AddPolicyCollectionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "provider",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "autoUpdate",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "PolicyCollectionItem",
  "kind": "LinkedField",
  "name": "items",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "version",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AddPolicyCollectionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "PolicyCollectionPayload",
        "kind": "LinkedField",
        "name": "addPolicyCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PolicyCollection",
            "kind": "LinkedField",
            "name": "collection",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PolicyCollectionMappingConnection",
                "kind": "LinkedField",
                "name": "policyMappings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PolicyCollectionMappingEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PolicyCollectionMapping",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Policy",
                            "kind": "LinkedField",
                            "name": "policy",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AddPolicyCollectionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "PolicyCollectionPayload",
        "kind": "LinkedField",
        "name": "addPolicyCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PolicyCollection",
            "kind": "LinkedField",
            "name": "collection",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PolicyCollectionMappingConnection",
                "kind": "LinkedField",
                "name": "policyMappings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PolicyCollectionMappingEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PolicyCollectionMapping",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Policy",
                            "kind": "LinkedField",
                            "name": "policy",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "collection",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "PolicyCollectionEdge"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c7e37c6de1167f6c6c073fa12f35cee7",
    "id": null,
    "metadata": {},
    "name": "AddPolicyCollectionMutation",
    "operationKind": "mutation",
    "text": "mutation AddPolicyCollectionMutation(\n  $input: AddPolicyCollectionInput!\n) {\n  addPolicyCollection(input: $input) {\n    collection {\n      id\n      uuid\n      name\n      description\n      provider\n      autoUpdate\n      items {\n        uuid\n        name\n        version\n      }\n      policyMappings {\n        edges {\n          node {\n            policy {\n              name\n              id\n            }\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "19e50c21b7fba02b148431051c24feb3";

export default node;
