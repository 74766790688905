/**
 * @generated SignedSource<<22a96481c427f25adc53c42f7bac3eda>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SectionModalDetails_section$data = {
  readonly description: string | null | undefined;
  readonly key: string;
  readonly numeral: string | null | undefined;
  readonly title: string;
  readonly topSection: {
    readonly title: string;
  };
  readonly " $fragmentType": "SectionModalDetails_section";
};
export type SectionModalDetails_section$key = {
  readonly " $data"?: SectionModalDetails_section$data;
  readonly " $fragmentSpreads": FragmentRefs<"SectionModalDetails_section">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SectionModalDetails_section",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "key",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numeral",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "DashboardSection",
      "kind": "LinkedField",
      "name": "topSection",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "DashboardSection",
  "abstractKey": null
};
})();

(node as any).hash = "17da457ada2898b0919eac10871ae3f3";

export default node;
