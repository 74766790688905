/**
 * @generated SignedSource<<0363b8852aa36c1d1f62166b41560009>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExecutionLog_log$data = {
  readonly log: string | null | undefined;
  readonly " $fragmentType": "ExecutionLog_log";
};
export type ExecutionLog_log$key = {
  readonly " $data"?: ExecutionLog_log$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExecutionLog_log">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExecutionLog_log",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "log",
      "storageKey": null
    }
  ],
  "type": "PolicyExecution",
  "abstractKey": null
};

(node as any).hash = "a6b57910767fad517f3f8f09c3e1ecf3";

export default node;
