/**
 * @generated SignedSource<<d8bd34ee8c445916c32ced18bcd08513>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type RemovePolicyCollectionMappingsInput = {
  ids: ReadonlyArray<string>;
};
export type RemovePolicyCollectionMappingsMutation$variables = {
  input: RemovePolicyCollectionMappingsInput;
};
export type RemovePolicyCollectionMappingsMutation$data = {
  readonly removePolicyCollectionMappings: {
    readonly removed: ReadonlyArray<{
      readonly id: string;
    }>;
  };
};
export type RemovePolicyCollectionMappingsMutation = {
  response: RemovePolicyCollectionMappingsMutation$data;
  variables: RemovePolicyCollectionMappingsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemovePolicyCollectionMappingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemovePolicyCollectionMappingsPayload",
        "kind": "LinkedField",
        "name": "removePolicyCollectionMappings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RemovedNode",
            "kind": "LinkedField",
            "name": "removed",
            "plural": true,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemovePolicyCollectionMappingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemovePolicyCollectionMappingsPayload",
        "kind": "LinkedField",
        "name": "removePolicyCollectionMappings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RemovedNode",
            "kind": "LinkedField",
            "name": "removed",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "deleteRecord",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d85cb854611e12d0af52991af367f5d5",
    "id": null,
    "metadata": {},
    "name": "RemovePolicyCollectionMappingsMutation",
    "operationKind": "mutation",
    "text": "mutation RemovePolicyCollectionMappingsMutation(\n  $input: RemovePolicyCollectionMappingsInput!\n) {\n  removePolicyCollectionMappings(input: $input) {\n    removed {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "11b50febc9784a21135f067f260d0b83";

export default node;
