/**
 * @generated SignedSource<<7cfde601fece4d5fadc981bf1896d249>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type bootstrapCognitoQuery$variables = Record<PropertyKey, never>;
export type bootstrapCognitoQuery$data = {
  readonly Cognito: {
    readonly __typename: "Cognito";
  } | null | undefined;
  readonly platform: {
    readonly __typename: "Platform";
  };
};
export type bootstrapCognitoQuery = {
  response: bootstrapCognitoQuery$data;
  variables: bootstrapCognitoQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Cognito",
      "kind": "LinkedField",
      "name": "Cognito",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "bootstrapCognitoQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Platform",
        "kind": "LinkedField",
        "name": "platform",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      (v2/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "bootstrapCognitoQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Platform",
        "kind": "LinkedField",
        "name": "platform",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v2/*: any*/)
    ]
  },
  "params": {
    "cacheID": "87b618cb818d63ff80d70e7ec4f3e7be",
    "id": null,
    "metadata": {},
    "name": "bootstrapCognitoQuery",
    "operationKind": "query",
    "text": "query bootstrapCognitoQuery {\n  platform {\n    __typename\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "3aab1a634845070ecf6954e611307d3a";

export default node;
