/**
 * @generated SignedSource<<19278cfbeca7a81efd6a5cd91c76ae79>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FilterBooleanOperator = "AND" | "NOT" | "OR" | "%future added value";
export type FilterElementInput = {
  multiple?: FilterBooleanOperationInput | null | undefined;
  single?: FilterValueInput | null | undefined;
};
export type FilterValueInput = {
  name: string;
  operator?: string | null | undefined;
  value?: string | null | undefined;
};
export type FilterBooleanOperationInput = {
  operands: ReadonlyArray<FilterElementInput>;
  operator: FilterBooleanOperator;
};
export type PolicyCollectionAutocompleteRefetchQuery$variables = {
  filterElement?: FilterElementInput | null | undefined;
  first?: number | null | undefined;
};
export type PolicyCollectionAutocompleteRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PolicyCollectionAutocomplete_policyCollections">;
};
export type PolicyCollectionAutocompleteRefetchQuery = {
  response: PolicyCollectionAutocompleteRefetchQuery$data;
  variables: PolicyCollectionAutocompleteRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterElement"
  },
  {
    "defaultValue": 10,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PolicyCollectionAutocompleteRefetchQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "PolicyCollectionAutocomplete_policyCollections"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PolicyCollectionAutocompleteRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "filterElement",
            "variableName": "filterElement"
          },
          (v1/*: any*/)
        ],
        "concreteType": "PolicyCollectionConnection",
        "kind": "LinkedField",
        "name": "policyCollections",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PolicyCollectionEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PolicyCollection",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2d8aca318b8e11d8fe1a10105b31b75f",
    "id": null,
    "metadata": {},
    "name": "PolicyCollectionAutocompleteRefetchQuery",
    "operationKind": "query",
    "text": "query PolicyCollectionAutocompleteRefetchQuery(\n  $filterElement: FilterElementInput\n  $first: Int = 10\n) {\n  ...PolicyCollectionAutocomplete_policyCollections_3ASum4\n}\n\nfragment PolicyCollectionAutocomplete_policyCollections_3ASum4 on Query {\n  policyCollections(filterElement: $filterElement, first: $first) {\n    edges {\n      node {\n        name\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "21c02826e86977aa6b12cbb1344dd573";

export default node;
